export const getStorage =(key)=>{
    if (typeof window !== 'undefined') {
        return localStorage.getItem(key)
    }
    
}

export const setStorage =(key, value)=>{
    if (typeof window !== 'undefined') {
  
    localStorage.setItem(key, value)
    }
}