import React, { createContext } from 'react';
import { getStorage, setStorage } from '../utils';
export const ThemeContext = createContext({
  darkMode: false,
  toggleTheme: () => {   },
});

export class ThemeProvider extends React.Component {

    toggleTheme = () => {
    setStorage("dark-mode", !this.state.darkMode); 
    this.setState({ darkMode : !this.state.darkMode });
  };


  state = {
  darkMode: false,
  toggleTheme: this.toggleTheme
  };

  componentWillMount(){
    let mode = (getStorage("dark-mode")? getStorage("dark-mode") : false) === "true"? true: false; 
    this.setState( { darkMode: mode });
  }

  render() {
    return (
      <ThemeContext.Provider value={this.state}>
        {this.props.children}
      </ThemeContext.Provider>
    );
  }
}


export const ThemeConsumer = ThemeContext.Consumer;