import { getStorage } from "../utils";

function header() {
  return { "Content-Type": "application/json" };
}

function authHeader() {
  let authtoken = getStorage("token")? getStorage("token"): null;
  return { "Content-Type": "application/json", token: authtoken };
}

export { header , authHeader };